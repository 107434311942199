import React from 'react';
import VectorscaleLandingPage from './VectorscaleLandingPage';

function App() {
  return (
    <div className="App">
      <VectorscaleLandingPage />
    </div>
  );
}

export default App;